body {
  margin: 0;
  font-family: -apple-systrem, BlinkMacSystremFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
  color: #0d0d0d;
  background-color: #fef9f8;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.app a {
  color: #0d0d0d;
}

.app .header {
  background-color: #ef9273;
}

.app .header h1 {
  padding: 1rem 1rem 0.5rem;
  margin: 0;
  color: #fef9f8;
}

.app .header .navbar {
  padding: 0.5rem 1rem;
  border-top: 2px solid #0d0d0d;
  border-bottom: 2px solid #0d0d0d;
  background-color: #fef9f8;
  display: flex;
}

.app .header .navbar .menu-icon {
  margin-left: auto;
}

@media all and (min-width: 1024px) {
  .app .header .navbar .menu-icon {
    display: none;
  }
}

.app .navbar a {
  margin-right: 10px
}

.app .page-body {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.app .page-body .features {
  width: 200px;
  min-width: 200px;
  border-left: 1px solid #0d0d0d;
  padding: 1rem;
  position: absolute;
  background: #fef9f8;
}

.app .page-body .features.hide-sidebar {
  display: none;
}

@media all and (min-width: 1024px) {
  .app .page-body .features {
    position: relative;
  }
  .app .page-body .features.hide-sidebar {
    display: block;
  }
}

.app .page-body .features ol {
  list-style: none;
  padding-inline-start: 10px;
}

.app .page-body .content {
  padding: 1rem;
  width: 100%;
}

.app .page-body .content .post-title {
  display: flex;
  flex-flow: wrap;
}

.app .page-body .content .post-title span {
  margin-bottom: auto;
  margin-top: auto;
  margin-left: auto;
}

.app .page-body .content img {
  width: 75%;
  margin-left: 12.5%;
  margin-right: 12.5%;
}
